<template>
  <div class="about" style="background-color: white; width: 100%">
    <div class="pageTitle">
      <div
        style="left: 0.4rem; position: absolute; color: #fff"
        @click="goback"
      >
        <span><van-icon name="arrow-left" /></span>
      </div>
      <div class="title">修改密码</div>
    </div>
    <van-form @submit="onModify" class="login">
      <div class="login_pwd">
        <van-field
          v-model="orginalPwd"
          name="orginalPwd"
          type="password"
          label="原密码"
          placeholder="请输入原密码"
          clearable
          :rules="[
            { required: true },
            { validator: validatePwd, message: '请输入6-16位密码' },
          ]"
        />
        <van-field
          v-model="password"
          type="password"
          name="password"
          label="新密码"
          placeholder="请输入新密码"
          maxlength="16"
          clearable
          :rules="[
            { required: true },
            { validator: validatePwd, message: '请输入6-16位密码' },
          ]"
        />
        <van-field
          v-model="confirmPwd"
          name="confirmPwd"
          type="password"
          label="确认密码"
          placeholder="请再次输入密码"
          maxlength="16"
          clearable
          :rules="[
            { required: true },
            { validator: comparePwd, message: '两次输入密码不相同' },
            { validator: validatePwd, message: '请输入6-16位密码' },
          ]"
        >
        </van-field>
      </div>
      <div class="btn">
        <van-button round block type="info" native-type="submit" color="#25c9c3"
          >确认修改</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { modifyPwd } from "@/api/merchant";
import router from "@/router/index";
import {
  Button as VanButton,
  Dialog,
  Field as VanField,
  Form as VanForm,
  Icon as VanIcon,
} from "vant";
import Cookies from "js-cookie";
export default {
  name: "MerchantModifyPwd",
  components: {
    VanButton,
    VanForm,
    VanIcon,
    VanField,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      orginalPwd: "",
      password: "",
      confirmPwd: "",
    };
  },
  computed: {},
  methods: {
    onModify(val) {
      const toast = this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: "修改中...",
      });
      modifyPwd({
        userId: Cookies.get("userId"),
        orginalPwd: val.orginalPwd,
        password: val.password,
      })
        .then(() => {
          Cookies.remove("token");
          Cookies.remove("mer_id");
          Cookies.remove("mch");
          Cookies.remove("userId");
          this.$toast.success("修改成功，请重新登录");
          router.push("/login");
        })
        .catch(() => {
          toast.clear();
          this.$toast.fail("修改密码失败");
        });
    },

    validatePwd(val) {
      return val.length >= 6 && val.length <= 16;
    },
    comparePwd(val) {
      return val == this.password;
    },
    goback() {
      this.$router.go(-1);
    },
  },
  created() {},
};
</script>

<style lang="less" scoped>
.van-form {
  min-height: calc(100vh);
  box-sizing: border-box;
  padding-top: 25px;
  .pwd2sms {
    height: 15px;
    padding: 10px 16px;
    padding-bottom: 0;
    font-size: 12px;
    font-weight: normal;
    color: #25c9c3;
    text-align: right;
    span {
      cursor: pointer;
    }
  }
  .btn {
    margin: 25px 16px;
  }
}
/deep/ .van-button {
  border-radius: 0.3rem;
  background: @mch-color !important;
  border-color: @mch-color !important;
}
</style>

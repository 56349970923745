var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"about",staticStyle:{"background-color":"white","width":"100%"}},[_c('div',{staticClass:"pageTitle"},[_c('div',{staticStyle:{"left":"0.4rem","position":"absolute","color":"#fff"},on:{"click":_vm.goback}},[_c('span',[_c('van-icon',{attrs:{"name":"arrow-left"}})],1)]),_c('div',{staticClass:"title"},[_vm._v("修改密码")])]),_c('van-form',{staticClass:"login",on:{"submit":_vm.onModify}},[_c('div',{staticClass:"login_pwd"},[_c('van-field',{attrs:{"name":"orginalPwd","type":"password","label":"原密码","placeholder":"请输入原密码","clearable":"","rules":[
          { required: true },
          { validator: _vm.validatePwd, message: '请输入6-16位密码' } ]},model:{value:(_vm.orginalPwd),callback:function ($$v) {_vm.orginalPwd=$$v},expression:"orginalPwd"}}),_c('van-field',{attrs:{"type":"password","name":"password","label":"新密码","placeholder":"请输入新密码","maxlength":"16","clearable":"","rules":[
          { required: true },
          { validator: _vm.validatePwd, message: '请输入6-16位密码' } ]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('van-field',{attrs:{"name":"confirmPwd","type":"password","label":"确认密码","placeholder":"请再次输入密码","maxlength":"16","clearable":"","rules":[
          { required: true },
          { validator: _vm.comparePwd, message: '两次输入密码不相同' },
          { validator: _vm.validatePwd, message: '请输入6-16位密码' } ]},model:{value:(_vm.confirmPwd),callback:function ($$v) {_vm.confirmPwd=$$v},expression:"confirmPwd"}})],1),_c('div',{staticClass:"btn"},[_c('van-button',{attrs:{"round":"","block":"","type":"info","native-type":"submit","color":"#25c9c3"}},[_vm._v("确认修改")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }